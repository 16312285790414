<template>
	<div>
        <Dialog :header="$t('Ingresar')" v-model:visible="displayLogin" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true" style="background: #727376;position: absolute;">
            <div class="p-fluid">
				<img  src="images/logo1.webp" style="margin-left: -20px;" width="100"  height="38">				
                <div class="p-field p-grid">
                    <label class="p-col-12 p-md-2">{{$t('Correo')}}</label>    
                    <div class="p-col-12 p-md-10">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <InputText v-model="correo" type="email" autofocus id="corre" />
                        </div>
                    </div>
                </div>	
                <div class="p-field p-grid">
                    <label class="p-col-12 p-md-2">{{$t('Contraseña')}}</label>
                    <div class="p-col-12 p-md-10">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-key"></i>
                            </span>
                            <InputText v-model="clave" type="password" @keyup.enter="Login" id="clave" />
                        </div>
                    </div>
                </div>
			</div>	
            <template #footer>
                <Button :disabled ="$store.state.loading" :label="$t('Aceptar')" icon="pi pi-check" class="p-button-secondary" @click="Login"/>
                <Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="close"/>
                <Button :disabled ="$store.state.loading" :label="$t('Recuperar')" icon="pi pi-key" class="p-button-warning" @click="openRecovery"/>
            </template>
        </Dialog>
        <Dialog :header="$t('Recuperar')" v-model:visible="displayRecovery" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true" style="background: #727376;position: absolute;">
            <div class="p-fluid">
				<img  src="images/logo1.webp" style="margin-left: -20px;" width="100"  height="38">				
                <div class="p-field p-grid">
                    <label class="p-col-12 p-md-2">{{$t('Correo')}}</label>    
                    <div class="p-col-12 p-md-10">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <InputText v-model="correo" type="email" disabled autofocus id="correo1" />
                        </div>
                    </div>
                </div>	
                <div class="p-field p-grid">
                    <label class="p-col-12 p-md-2">{{$t('Código Validación')}}</label>
                    <div class="p-col-12 p-md-10">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-unlock"></i>
                            </span>
                            <InputText v-model="codigo" type="text" @keyup.enter="Verificar" :disabled="validar == true"/>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12" v-if="!validar">
                        <div class="p-inputgroup">
                            {{$t('Código Validación Mens')}}
                        </div>
                    </div>
                </div>
                <div class="p-field p-grid" v-if="validar">
					<div class="p-col-12 p-md-6">
						<label>{{$t('Contraseña')}}</label>
                        <InputText v-model="clave" type="password" id="clave"/>
					</div>
					<div class="p-col-12 p-md-6">
						<label>{{$t('Confirmar Contraseña')}}</label>
                        <InputText v-model="claveconf" type="password" id="clave0" />
					</div>
                    <div class="p-col-12 p-md-12">
						{{$t('Contraseña Format')}}
					</div>
                </div>
			</div>	
            <template #footer>
                <Button :disabled ="$store.state.loading" :label="$t('Aceptar')" icon="pi pi-check" class="p-button-secondary" @click="Reset" v-if="validar"/>
                <Button :disabled ="$store.state.loading" :label="$t('Verificar')" icon="pi pi-check" class="p-button-secondary" @click="ValidarCodigo" v-else/>
                <Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="close"/>
            </template>
        </Dialog>
	</div>
</template>

<script>
import API from "../service/API";
import Crypto from "crypto-js";
import url from "../service/_URL";

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		}
	},
    data() {
        return {
			displayLogin: false,
            displayRecovery: false,
            correo: null,
            clave: null,
            claveconf: null,
            validar: false,
            codigo: null,
            codigosend: null,
            size: '40vw',
            visibleFull: false
		}
	},
    created() {      
        this.correo = url.correo; 
        this.clave = url.clave;
        if (this.$store.state.mobile){this.size = '80vw';}
    },
    methods: {
        limpiar() {
            this.clave = null;
            this.claveconf = null;
            this.validar = false;
            this.displayRecovery = false;
            this.codigo = null;
            this.codigosend = null;
        },
        openRecovery() {
            if (this.correo == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Introduzca correo'), life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Correo'), life: 10000});
            } else {
                const Consulta = new API('Autenticacion','Reset'); 
                Consulta.Procesar(null,{
                    correo: this.correo
                }).then(response => {
                    if(response.correo){
                        //alert(response.codigo)
                        this.codigosend = response.codigo;
                        this.$toast.add({severity:'success', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                        this.displayLogin = false;
                        this.displayRecovery = true;
                    } else {
                        this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                    } 
                });         
            }     
        },
        ValidarCodigo() {
            if (this.codigo == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Introduzca código validación'), life: 10000});
            } else {
                if (this.codigo == this.codigosend) {
                    this.validar = true;
                } else {
                    this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error código de validación'), life: 10000});
                }
            }     
        },
        close() {
            this.displayLogin = false;
            this.displayRecovery = false;
            this.limpiar();
            this.$emit('close-login', event);
        },
        Login() { 
            if (this.correo == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Introduzca correo'), life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Correo'), life: 10000});
            } else if (this.clave == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Introduzca contraseña'), life: 10000});
            } else {
                const cifrado = Crypto.HmacSHA1(this.clave, "btcoin").toString();
                this.$store.state.clave = cifrado;
                const Consulta = new API('Autenticacion','Login');         
                Consulta.Procesar(null,{
                    correo: this.correo,
                    clave:  cifrado
                }).then(response => {
                    //this.$store.state.error=response;
                    if(response.authenticated){
                        this.$toast.add({severity:'success', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                        this.$store.commit('Login',response);
                        this.displayLogin = false;
                        this.$store.commit('Menu');
                        this.onMenuToggle();
                    } else {
                        this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                    }                    
                });
                this.limpiar();
            }
        },
        Reset() {
            if (this.clave == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Contraseña'), life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Correo'), life: 10000});
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_.!@#$%^&*])(?=.{8,})/.test(this.clave)) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Contraseña'), life: 10000});
            } else if (this.clave != this.claveconf) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Confirmación'), life: 10000});
            } else {
                const cifrado = Crypto.HmacSHA1(this.clave, "btcoin").toString();
                const Consulta = new API('Autenticacion','ResetClave');
                Consulta.Procesar(null,{
                    correo: this.correo,
                    codigo: this.codigo,
                    clave: cifrado
                }).then(response => {
                    if(response.error){
                        this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                    } else {
                        this.$toast.add({severity:'success', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                        this.limpiar();
                        this.displayLogin = true; 
                    }
                });
            } 
        },
        onMenuToggle(event) {
            this.$emit('aprob-login', event);
            this.$emit('close-login', event);
        },	
	},
    watch: {
		display(newValue) {
			this.displayLogin = newValue;
		}
	},


}
</script>